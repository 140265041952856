import React from "react";

import "./formField.scss";

const FormField = ({
  name,
  label,
  type,
  value,
  onChange,
  isInvalid,
  onFileUpload,
  errorMessage,
  className = "",
  readonly,
  explainer,
  options = [],
  variant,
  width = "",
}) => (
  <>
    {explainer && <span className="field-input__explainer">{explainer}</span>}
    <div
      className={`field-input field-${name} ${isInvalid && "invalid"} ${
        type || ""
      } ${value?.length ? "has-value" : ""} ${className} ${
        variant || ""
      } ${width}`}
    >
      {(() => {
        switch (type) {
          case "textarea":
            return (
              <textarea
                value={value}
                name={name}
                id={name}
                readOnly={readonly}
                onChange={(e) => onChange(e.target.value)}
              ></textarea>
            );
          case "choice":
            return (
              <select
                value={value}
                name={name}
                id={name}
                readOnly={readonly}
                onChange={(e) => onChange(e.target.value)}
              >
                {options.map((o, k) => (
                  <option value={o.value} key={`o-${k}-${name}`}>
                    {o.value}
                  </option>
                ))}
              </select>
            );

          case "file":
            return (
              <input
                id={name}
                name={name}
                type={type}
                readOnly={readonly}
                onChange={(e) => {
                  onFileUpload(e.target.files[0]);
                }}
              />
            );
          case "checkbox":
            return (
              <input
                id={name}
                name={name}
                type={type}
                readOnly={readonly}
                onChange={(e) => onChange(e.target.checked)}
              />
            );
          default:
            return (
              <input
                id={name}
                name={name}
                type={type}
                value={value}
                readOnly={readonly}
                onChange={(e) => onChange(e.target.value)}
              />
            );
        }
      })()}

      {label && <label htmlFor={name}> {label} </label>}

      {isInvalid && errorMessage && (
        <span className="error-message">{errorMessage}</span>
      )}
    </div>{" "}
  </>
);

export default FormField;
